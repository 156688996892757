@font-face {
  font-family: 'Garet-Book';
  src: 
      url('../fonts/Garet-Book.otf') format('opentype'),
      url('../fonts/Garet-Book.ttf') format('truetype'),
      url('../fonts/Garet-Book.woff') format('woff'),
      url('../fonts/Garet-Book.woff2') format('woff'),
}
@font-face {
  font-family: 'cralika';
  src: 
      url('../fonts/Cralika.otf') format('opentype'),
      url('../fonts/Cralika.ttf') format('truetype'),
      url('../fonts/Cralika.woff') format('woff'),
      url('../fonts/Cralika.woff2') format('woff'),
}
@font-face {
  font-family: 'Skeina';
  src: 
      url('../fonts/Skeina.otf') format('opentype'),
}
@font-face {
  font-family: 'griffiths';
  src: 
      url('../fonts/Griffiths_title.otf') format('opentype'),
}
@font-face {
  font-family: 'lemonmilk-bold';
  src: 
      url('../fonts/LEMONMILK-Bold.otf') format('opentype'),
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Garet-Book', sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

a {
  font-family: 'Garet-Book', sans-serif;
  text-decoration: none;
  color: black;
  cursor: pointer;
}